import { ReactNode, useState } from 'react';

import { IconButton, IconButtonProps, Menu, Tooltip } from '@mui/material';

import { ThreeDots } from '../icons';

export function ButtonMenu({
  title,
  children,
  iconButtonProps,
  customButton,
  removePadding = false,
  hasBgColor = false,
}: {
  title?: string;
  children: ReactNode;
  iconButtonProps?: IconButtonProps;
  customButton?: ReactNode;
  removePadding?: boolean;
  hasBgColor?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={title} enterTouchDelay={0}>
        <IconButton
          onClick={handleClick}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="secondary"
          {...iconButtonProps}
          sx={{
            padding: removePadding ? 0 : 2,
            bgcolor: hasBgColor ? '#262829' : 'transparent',
            width: !customButton ? { xs: '40px', md: '48px' } : 'auto',
            height: !customButton ? { xs: '40px', md: '48px' } : 'auto',
          }}
        >
          {customButton ?? <ThreeDots />}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.3))',
              borderRadius: '12px',
              mt: 3,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </Menu>
    </>
  );
}
