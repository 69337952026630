import { Box, Typography } from '@mui/material';

import { discordSelector } from '../../../constants/setupAccountSteps';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { CommunityPick } from '../../UserCardsOnboardSteps';
import CreatorSetupCard from '../Cards/CreatorSetupCard';
import { ModalContainer } from '../ModalContainer';
import { ConnectTelegramSetupCard } from '../SetupCards/ConnectTelegramSetupCard';

export interface CommunityPickModalProps {
  setCommunityPick: React.Dispatch<React.SetStateAction<CommunityPick | null>>;
}

export function CommunityPickModal({
  setCommunityPick,
}: CommunityPickModalProps) {
  const { hideModal } = useGlobalModal();

  const handleCommunityPick = async (community: CommunityPick) => {
    setCommunityPick(community);

    if (community === CommunityPick.DISCORD) {
      window.location.href = '/api/discord/link-account';
    } else {
      hideModal();
    }
  };

  return (
    <ModalContainer title="Which platform is your community on?">
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          gap: 4,
          pt: 6,
        }}
      >
        <ConnectTelegramSetupCard
          isCreator={true}
          isCommunityCard
          onComplete={hideModal}
        />

        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 300,
          }}
        >
          OR
        </Typography>
        <CreatorSetupCard
          key={discordSelector.key}
          buttonText={discordSelector.buttonText}
          action={() => {
            handleCommunityPick(CommunityPick.DISCORD);
          }}
          title={discordSelector.title}
          description={discordSelector.description}
          icon={discordSelector.icon}
          isSmall
          communityCard
        />
      </Box>
    </ModalContainer>
  );
}
