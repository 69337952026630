import { GridProps } from '@mui/material';

import { getConnectTelegramStepDetails } from '../../../constants/setupAccountSteps';
import { useTgConnection } from '../../../hooks/useTgConnection';
import { useProfileSetupProgress } from '../../../providers/ProfileSetupProgressProvider';
import { TelegramWidget } from '../Buttons/TelegramLoginButton';
import CreatorSetupCard from '../Cards/CreatorSetupCard';

export function ConnectTelegramSetupCard({
  isCreator,
  isCommunityCard = false,
  onComplete,
  ...gridProps
}: {
  isCreator: boolean;
  isCommunityCard?: boolean;
  onComplete?: () => void;
} & GridProps) {
  const { connectTg } = useTgConnection();
  const { isTelegramSetupDone } = useProfileSetupProgress();

  const handleAction = () => {
    connectTg().finally(() => {
      onComplete?.();
    });
  };

  const creatorConnectTelegramStep = getConnectTelegramStepDetails(
    isCreator,
    isCommunityCard,
  );

  return (
    <>
      <CreatorSetupCard
        key={creatorConnectTelegramStep.key}
        buttonText={creatorConnectTelegramStep.buttonText}
        action={handleAction}
        isDone={isTelegramSetupDone}
        disabled={isTelegramSetupDone}
        title={creatorConnectTelegramStep.title}
        description={creatorConnectTelegramStep.description}
        icon={creatorConnectTelegramStep.icon}
        isSmall={isCommunityCard}
        communityCard={isCommunityCard}
        {...gridProps}
      />
      {!isTelegramSetupDone && <TelegramWidget />}
    </>
  );
}
