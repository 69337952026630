import { styled } from '@mui/material';

import { ArrowsUpDown } from '.';

export const RotatedArrowsUpDownIcon = styled(ArrowsUpDown)(
  ({ theme, rotate, disabled }) => ({
    transition: 'transform 0.1s',
    transform: rotate ? 'rotate(270deg)' : 'rotate(90deg)',
    fill: disabled ? '#6D7073' : '#38FF93',
  }),
);
