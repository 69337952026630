// --- Signup Events ---
export const CONNECT_X_EVENT = 'connect_x';
export const COMPLETED_SIGN_UP_EVENT = 'completed_sign_up';

// --- Creator Onboarding Events ---
export const SETUP_BASIC_PROFILE_EVENT = 'setup_basic_profile';
export const TOP_UP_WALLET_EVENT = 'top_up_your_wallet';
export const CONNECT_TELEGRAM_EVENT = 'connect_your_telegram';
export const SETUP_PRICING_EVENT = 'setup_your_pricing';

// --- Subscription Tracking Events ---
export const MONTHLY_SUBS = 'monthly_subs';
export const LIFETIME_SUBS = 'lifetime_subs';

// --- Creator Events ---
export const BECOME_CREATOR_EVENT = 'become_creator';
