import { Address } from 'viem';
import { useAccount } from 'wagmi';

import { useHasAnyExpiredSoonSubscription } from '../api/subscriptions/useHasAnyExpiredSoonSubscription';
import { SUPPORTED_CONNECTORS_ID } from '../configs/wagmi';
import { useAllowanceReadMethod } from '../web3/hooks/ERC20/useAllowanceReadMethod';

import useCurrentUserData from './auth/useCurrentUserData';

export enum GlobalConnectErrors {
  WrongConnector = 'WrongConnector',
  NoWalletConnected = 'NoWalletConnected',
  WrongNetwork = 'WrongNetwork',
  WalletNotLinked = 'WalletNotLinked',
  DifferFromLinkedWallet = 'DifferFromLinkedWallet',
  EmailNotLinked = 'EmailNotLinked',
  SubscriptionsExpiredSoon = 'SubscriptionsExpiredSoon',
  OldPullPaymentApproved = 'OldPullPaymentApproved',
}

export const SUBSCRIPTION_EXPIRED_PERIOD_DAYS = 7;

export function useGlobalConnectError() {
  const { address, isConnected, chain, connector } = useAccount();
  const { data: userData, isLoading } = useCurrentUserData();
  const { data: hasExpiredSoonSubscription } = useHasAnyExpiredSoonSubscription(
    userData?.id,
    SUBSCRIPTION_EXPIRED_PERIOD_DAYS,
  );

  const oldPullPaymentContractAddress = import.meta.env
    .VITE_OLD_PAYMENT_COLLECTOR_ADDRESS as Address;
  const { data: oldPullPaymentAllowance } = useAllowanceReadMethod(
    address,
    oldPullPaymentContractAddress,
  );

  const oldPullPaymentApproved =
    oldPullPaymentAllowance !== undefined &&
    oldPullPaymentAllowance !== null &&
    oldPullPaymentAllowance > 0n;

  if (connector && !SUPPORTED_CONNECTORS_ID.includes(connector.id)) {
    return { errorType: GlobalConnectErrors.WrongConnector };
  }

  if (!isConnected) {
    return {
      errorType: GlobalConnectErrors.NoWalletConnected,
    };
  }

  if (!chain) {
    return { errorType: GlobalConnectErrors.WrongNetwork };
  }

  if (userData && !userData?.wallet_address) {
    return { errorType: GlobalConnectErrors.WalletNotLinked };
  }

  if (userData?.wallet_address !== address) {
    return {
      errorType: GlobalConnectErrors.DifferFromLinkedWallet,
    };
  }

  if (oldPullPaymentApproved) {
    return { errorType: GlobalConnectErrors.OldPullPaymentApproved };
  }

  if (hasExpiredSoonSubscription) {
    return { errorType: GlobalConnectErrors.SubscriptionsExpiredSoon };
  }
  // const emailWarning = !isLoading && !!userData && !userData.email;
  // if (emailWarning) {
  //   return {
  //     errorType: GlobalConnectErrors.EmailNotLinked,
  //   };
  // }

  return { errorType: null };
}
