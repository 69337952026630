import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import { User } from '@supabase/supabase-js';

import useSupabase from '../api/supabase';
import { usePostHogCapture } from '../hooks/usePostHogCapture';
import { usePageContext } from '../renderer/usePageContext';

interface Session {
  data: User | null;
  error: Error | null;
  isLoading: boolean;
}

export const SessionContext = createContext<Session>({
  data: null,
  error: null,
  isLoading: false,
});

export function SessionProvider({ children }: { children: ReactNode }) {
  const { sessionUser } = usePageContext();
  const supabase = useSupabase();
  const { identify } = usePostHogCapture();

  useEffect(() => {
    // TODO - We need to properly set user session / page context instead of adding a manual reload.
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION' && session?.user) {
        identify();
      }

      if (!sessionUser?.data?.user && session?.user) {
        window.location.reload();
        return;
      }

      if (event === 'SIGNED_OUT') {
        window.location.reload();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase, sessionUser]);

  const value = useMemo(
    () => ({
      data: sessionUser?.data?.user ?? null,
      error: sessionUser?.error ?? null,
      isLoading: false,
    }),
    [sessionUser?.data?.user, sessionUser?.error],
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}

export function useSessionUser() {
  return useContext(SessionContext);
}
