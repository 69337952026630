import {
  Backdrop,
  Box,
  BoxProps,
  Button,
  Fade,
  IconButton,
  Modal,
  ModalProps,
  Typography,
} from '@mui/material';

import useGlobalModal from '../../hooks/useGlobalModal';
import { useGlobalConnectError } from '../../hooks/useWalletConnectError';
import { ArrowLeftIcon, CloseIcon } from '../icons';
import { ChevronRight } from '../icons/chevronRight';

export function ModalContainer({
  title,
  description,
  onClose,
  onBack,
  contentProps,
  topLeftIcon,
  ...props
}: Omit<ModalProps, 'open'> & {
  onBack?: any;
  description?: string | JSX.Element;
  hasgradientBackground?: boolean;
  contentProps?: BoxProps;
  topLeftIcon?: JSX.Element;
}) {
  const { hideModal } = useGlobalModal();
  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    hideModal();
    onClose?.(event, reason);
  };

  const ariaDescription =
    typeof description === 'string' ? description : `${title} modal`;

  const { errorType } = useGlobalConnectError();

  const errorShown = errorType !== null;

  return (
    <Modal
      open
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
          sx: {
            backdropFilter: 'blur(12px)',
          },
        },
      }}
      aria-label={`${title} modal`}
      aria-description={ariaDescription}
      {...props}
      sx={{
        overflow: 'scroll',
        ...props.sx,
      }}
    >
      <Fade in={true}>
        <Box
          display="flex"
          flexDirection="column"
          {...contentProps}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#090C11',
            border: '1px solid #262829',
            borderColor: '#222426',
            outline: 'none',
            borderRadius: 3,
            p: { xs: 5, md: 10 },
            maxHeight: { xs: '100vh', sm: '100%' },
            width: { xs: '100vw', sm: 'auto' },
            ...(errorShown && {
              maxHeight: { xs: 'calc(100vh - 58px)', sm: '100%' },
              height: { xs: '100vh', sm: 'auto' },
              top: { xs: '58px', sm: '50%' },
              transform: {
                xs: 'translate(-50%, 0%)',
                sm: 'translate(-50%, -50%)',
              },
            }),
            ...contentProps?.sx,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 11,
            }}
          >
            {onBack && (
              <Button
                variant="text"
                onClick={(event) => onBack?.(event, 'backButtonClick' as any)}
                sx={{
                  color: '#828282',
                  stroke: '#828282',
                  fontSize: '14px',
                  fontWeight: 400,
                  gap: 3,
                }}
              >
                <ChevronRight changeDirection stroke="#9B9FA3" />
                Back
              </Button>
            )}

            <Box flex={1} />

            <IconButton
              onClick={(event) =>
                handleClose?.(event, 'closeButtonClick' as any)
              }
              sx={{ stroke: '#828282' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {topLeftIcon && <Box sx={{ pb: 2 }}>{topLeftIcon}</Box>}

          <Box>
            {title && (
              <Typography variant="h1" fontSize="24px" fontWeight="500">
                {title}
              </Typography>
            )}

            {description && (
              <Typography
                variant="h2"
                fontSize="14px"
                fontWeight="300"
                color="#ACAFB3"
                mt={2}
              >
                {description}
              </Typography>
            )}
          </Box>

          <>{props.children}</>
        </Box>
      </Fade>
    </Modal>
  );
}
