import {
  CheckRounded,
  CommunityAltcoinistColorIcon,
  DiscordAltcoinistColorIcon,
  DiscordIcon,
  SetupPricingIcon,
  TelegramAltcoinistColorIcon,
  TelegramBlueIcon,
  TopUpYourWalletIcon,
} from '../components/icons';

interface ISetupAccountSteps {
  key: string;
  title: string;
  description: string;
  successMessage: string;
  icon: JSX.Element;
  buttonText?: string;
  action: () => void;
  isDone?: boolean;
}

export const creatorTopUpWalletStep = {
  key: 'topUpWallet',
  title: 'You need ETH for gas',
  description: 'Get ETH on Base to cover gas for setting up your pricing.',
  successMessage: 'Your wallet is topped up!',
  icon: <TopUpYourWalletIcon size={24} />,
  buttonText: 'Top up',
  action: () => {},
};

export const followerTopUpWalletStep = {
  key: 'topUpWallet',
  title: 'Top up your wallet',
  description:
    'Top up your wallet with ETH to spend on memberships to join communities.',
  successMessage: 'Your wallet is topped up!',
  icon: <TopUpYourWalletIcon size={24} />,
  buttonText: 'Top up',
  action: () => {},
};

export const getConnectTelegramStepDetails = (
  isCreator: boolean,
  isBlueIcon: boolean = false,
) => ({
  key: 'connectTelegram',
  title: 'Connect your telegram',
  description: isCreator
    ? 'Connect your telegram to setup your community.'
    : 'Connect your account to join communities in Telegram',
  successMessage: 'Your telegram is connected!',
  icon: isBlueIcon ? (
    <TelegramBlueIcon size={24} />
  ) : (
    <TelegramAltcoinistColorIcon size={24} />
  ),
  buttonText: 'Connect',
  action: () => {},
});

export const creatorConnectDiscordStep = {
  key: 'connectDiscord',
  title: 'Connect Discord Server',
  description: 'Step 2: Connect your private Discord server.',
  successMessage: 'Your discord is connected!',
  icon: <DiscordAltcoinistColorIcon />,
  buttonText: 'Connect',
  action: () => {},
};

export const creatorConnectCommunity = {
  key: 'connectCommunity',
  title: 'Connect your Community',
  description: 'Connect your Telegram or Discord community.',
  successMessage: 'Your Community is connected!',
  icon: <CommunityAltcoinistColorIcon size={24} />,
  buttonText: 'Connect',
  action: () => {},
};

export const setupBasicProfileStep = {
  key: 'setupBasicProfile',
  title: 'Setup basic profile',
  description: 'You’ve connected your X account and your wallet.',
  successMessage: 'You’ve connected your X account and your wallet.',
  buttonText: '',
  icon: <CheckRounded size={24} />,
  action: () => {},
  isDone: true,
};

export const setupPricingDefaultStep = {
  key: 'setupPricing',
  title: 'Setup your pricing',
  description: 'Setup pricing for your private community.',
  successMessage: 'Your pricing is set!',
  icon: <SetupPricingIcon size={24} />,
  buttonText: 'Setup pricing',
  action: () => {},
};

export const connectTelegramGroupStep = {
  key: 'connectTelegramGroup',
  title: 'Connect Telegram Group',
  description: 'Connect your telegram group or create a new one.',
  successMessage: 'Your telegram group is connected!',
  icon: <TelegramAltcoinistColorIcon size={24} />,
  buttonText: 'Connect',
  action: () => {},
};

export const telegramSelector = {
  key: 'telegramSelector',
  title: 'Telegram',
  description: 'Connect your Telegram account and community.',
  icon: <TelegramBlueIcon />,
  buttonText: 'Connect Telegram',
};

export const discordSelector = {
  key: 'discordSelector',
  title: 'Discord',
  description: 'Connect your Discord account and community.',
  icon: <DiscordIcon />,
  buttonText: 'Connect Discord',
};
