import { useDisconnect } from 'wagmi';

import { Box, Button, Typography } from '@mui/material';

import { WalletWarningImage } from '../../components/icons';

export function WrongConnectorWarningContent() {
  const { disconnect } = useDisconnect();

  const handleConnectWallet = async () => {
    disconnect();
  };

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box display="flex" justifyContent="center">
        <WalletWarningImage />
      </Box>

      <Box>
        <Typography fontSize={24} fontWeight={500} lineHeight={1.2}>
          To continue, disconnect this wallet, and connect a Coinbase Smart
          Wallet.
        </Typography>

        <Typography
          fontSize={14}
          fontWeight={300}
          color="text.secondary"
          mt={2}
        >
          We plan to support more wallet options when they roll out their native
          Smart Wallet options as well.
        </Typography>
      </Box>

      <Box display="flex" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConnectWallet}
        >
          Disconnect wallet
        </Button>
      </Box>
    </Box>
  );
}
