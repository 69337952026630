import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { bigIntToBigNumber } from '../lib/bigIntToBigNumber';
import { useGetUniswapSlot0 } from '../web3/hooks/uniswap/useGetUniswapSlot0';

import { useAlttTokenInfo } from './useAlttTokenInfo';
import { useMainTokenInfo } from './useMainTokenInfo';

export function calculatePriceFromSqrtPrice(
  sqrtPriceX96: BigNumber,
): BigNumber {
  const sqrtPrice = sqrtPriceX96.div(new BigNumber(2).pow(96));
  return sqrtPrice.pow(2);
}

export function calculatePriceWithDecimals(
  value: BigNumber,
  decimalToken0: number,
  decimalToken1: number,
): BigNumber {
  const decimalToken0Big = new BigNumber(decimalToken0);
  const decimalToken1Big = new BigNumber(decimalToken1);

  const decimalDiff = new BigNumber(10).pow(
    decimalToken0Big.minus(decimalToken1Big),
  );
  const price = value.multipliedBy(decimalDiff);

  return price;
}

export function useAlttWethPrice(): {
  price: BigNumber | null;
  isLoading: boolean;
} {
  const { decimals: wethDecimals } = useMainTokenInfo();
  const { decimals: alttDecimals } = useAlttTokenInfo();
  const { data: slot0, isLoading } = useGetUniswapSlot0();

  const price = useMemo(() => {
    if (!slot0) return null;

    const [sqrtPriceX96] = slot0;
    const sqrtPriceX96Big = bigIntToBigNumber(sqrtPriceX96);
    const valueBig = calculatePriceFromSqrtPrice(sqrtPriceX96Big);
    const priceWithDecimals = calculatePriceWithDecimals(
      valueBig,
      wethDecimals,
      alttDecimals,
    );
    return new BigNumber(1).div(priceWithDecimals);
  }, [slot0, wethDecimals, alttDecimals]);

  return { price, isLoading };
}

export function useAlttWethConverter() {
  const { price: alttPerEth, isLoading } = useAlttWethPrice();

  function convertAlttToEth(amountEth: number | null) {
    if (
      amountEth === null ||
      alttPerEth === null ||
      alttPerEth === new BigNumber(0)
    )
      return null;
    return amountEth / alttPerEth.toNumber();
  }

  function convertEthToAltt(amountInAltt: number | null) {
    if (amountInAltt === null || alttPerEth === null) return null;
    return amountInAltt * alttPerEth.toNumber();
  }

  function convertAlttToEthWei(amountEthWei: bigint | null) {
    if (
      amountEthWei === null ||
      alttPerEth === null ||
      alttPerEth === new BigNumber(0)
    )
      return null;

    const res = bigIntToBigNumber(amountEthWei).div(alttPerEth);
    return BigInt(res.toFixed(0));
  }

  function convertEthToAlttWei(amountInAlttWei: bigint | null) {
    if (amountInAlttWei === null || alttPerEth === null) return null;

    const res = bigIntToBigNumber(amountInAlttWei).multipliedBy(alttPerEth);
    return BigInt(res.toFixed(0));
  }

  return {
    convertAlttToEth,
    convertEthToAltt,
    convertAlttToEthWei,
    convertEthToAlttWei,
    isPriceLoading: isLoading,
  };
}
