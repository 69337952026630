import { Address } from 'viem';
import { useSimulateContract } from 'wagmi';

import SubscribeRegistryAbi from '../../../assets/abi/SubscribeRegistry';

export function useSetSubPricesSimulation(
  monthPriceInWei: bigint | null,
  lifetimePriceInWei: bigint | null,
  username: string,
) {
  return useSimulateContract({
    address: import.meta.env.VITE_SUBSCRIBE_REGISTRY_ADDRESS as Address,
    abi: SubscribeRegistryAbi,
    functionName: 'setSubPrice',
    args: [monthPriceInWei ?? 0n, lifetimePriceInWei ?? 0n, username],
    query: {
      enabled: monthPriceInWei !== null && lifetimePriceInWei !== null,
    },
  });
}
