import { Box, Divider, Typography } from '@mui/material';

import { SubscriptionPlanType } from '../../../../types/SubscriptionPlanType';
import { SelectionPlanSidePanel } from '../../../SelectionPlanSidePanel';
import { SubscriptionPanel } from '../../../SubscriptionPanel';
import { ModalContainer } from '../../ModalContainer';

export interface SwitchPlanModalProps {
  username: string;
}

export function SwitchPlanModal({ username }: SwitchPlanModalProps) {
  const planSelected = SubscriptionPlanType.LIFETIME;

  return (
    <ModalContainer>
      <Box
        display="flex"
        m={-10}
        sx={{
          flexDirection: { xs: 'column-reverse', md: 'row' },
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mx={{ xs: 10, md: 14 }}
          my={{ xs: 5, md: 10 }}
          gap={{ xs: 0, md: 10 }}
          flex={1}
        >
          <Typography fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>
            Review your subscription
          </Typography>

          <SubscriptionPanel
            planSelected={planSelected}
            username={username}
            affilateWalletParam={null}
          />
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mx={{ xs: 10, md: 14 }}
          mt={{ xs: 15, md: 10 }}
          mb={{ xs: 5, md: 10 }}
          gap={{ xs: 5, md: 10 }}
          flex={1}
        >
          <Typography fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>
            You are switching to
          </Typography>

          <SelectionPlanSidePanel
            planSelected={planSelected}
            username={username}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
}
