import { Address } from 'viem';
import { useReadContract } from 'wagmi';

import SubstakingVault from '../../../../assets/abi/SubstakingVault';

export function useGetWethDepositReadContract(
  vaultContractAddress: Address | undefined,
  myAddress: Address | undefined,
  enabled: boolean = true,
) {
  return useReadContract({
    address: vaultContractAddress,
    abi: SubstakingVault,
    functionName: 'getWethDeposit',
    args: [myAddress],
    query: {
      enabled: !!vaultContractAddress && !!myAddress && enabled,
    },
  });
}
