import { usePostHog } from 'posthog-js/react';

import {
  BECOME_CREATOR_EVENT,
  COMPLETED_SIGN_UP_EVENT,
  CONNECT_TELEGRAM_EVENT,
  CONNECT_X_EVENT,
  LIFETIME_SUBS,
  MONTHLY_SUBS,
  SETUP_BASIC_PROFILE_EVENT,
  SETUP_PRICING_EVENT,
  TOP_UP_WALLET_EVENT,
} from '../constants/posthogEvents';
import { usePageContext } from '../renderer/usePageContext';
import {
  ConnectTelegramProps,
  SetupPricingProps,
  SubscriptionProps,
  TopUpWalletProps,
  UserProps,
} from '../types/Posthog';

export function usePostHogCapture() {
  const posthog = usePostHog();
  const { userData } = usePageContext();

  if (posthog && !posthog.__loaded) {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      person_profiles: 'identified_only',
    });
  }

  const userProps: UserProps = {
    distinct_id: userData?.id,
    x_account_name: userData?.username,
    wallet_address: userData?.wallet_address,
    user_type: userData?.user_type,
    email: userData?.email,
  };

  return {
    identify: () => identify(),
    captureConnectX: () => captureEvent(CONNECT_X_EVENT),
    captureOnSignUpComplete: (user_type: UserProps['user_type']) =>
      captureEvent(COMPLETED_SIGN_UP_EVENT, { user_type }),
    captureSetupBasicProfile: (user_type: UserProps['user_type']) =>
      captureEvent(SETUP_BASIC_PROFILE_EVENT, { user_type }),
    captureTopUpWallet: (props?: TopUpWalletProps) =>
      captureEvent(TOP_UP_WALLET_EVENT, props),
    captureConnectTelegram: (props: ConnectTelegramProps) =>
      captureEvent(CONNECT_TELEGRAM_EVENT, props),
    captureSetupPricing: (props: SetupPricingProps) =>
      captureEvent(SETUP_PRICING_EVENT, props),
    captureMonthlySubs: (props: SubscriptionProps) =>
      captureEvent(MONTHLY_SUBS, props),
    captureLifetimeSubs: (props: SubscriptionProps) =>
      captureEvent(LIFETIME_SUBS, props),
    captureBecomeCreator: (user_type: UserProps['user_type']) =>
      captureEvent(BECOME_CREATOR_EVENT, { user_type }),
  };

  function identify() {
    if (!posthog.__loaded || !userData) return;

    // Only identify if it hasn't been done in this session
    const hasIdentified = sessionStorage.getItem('posthog_identified');
    if (!hasIdentified) {
      posthog.identify(userProps.distinct_id, userProps);
      sessionStorage.setItem('posthog_identified', 'true');
    }
  }

  function captureEvent<T>(event: string, customProps?: T) {
    if (!posthog.__loaded || !userData) return;
    posthog.capture(event, { ...userProps, ...customProps });
  }
}
