import { Box } from '@mui/material';

import { GlobalWarningContainer } from '../../components/GlobalWarnings/GlobalWarningContainer';

import { WrongConnectorWarningContent } from './WrongConnectorWarningContent';

export function WrongConnectorWarningBlockingView() {
  return (
    <>
      <GlobalWarningContainer />

      <Box
        sx={{
          width: '100vw',
          height: 'calc(100vh - 50px)',
          my: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            p: 10,
            border: '1px solid #2D2F33',
            borderRadius: 3,
            maxWidth: '490px',
          }}
        >
          <WrongConnectorWarningContent />
        </Box>
      </Box>
    </>
  );
}
