import { SupabaseClient } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

import { currentTime } from '../../lib/datetime/currentTime.util';
import { getDateAfterNow } from '../../lib/datetime/dateAfterNow';
import useSupabase from '../supabase';

export function useHasAnyExpiredSoonSubscription(
  userId: string,
  daysToExpiration: number,
) {
  const client = useSupabase();
  const queryKey = ['notifyUser', userId];

  const queryFn = async () => {
    return getAtLeastOneExpiredSoonSubscription(
      client,
      userId,
      daysToExpiration,
    ).then((result: any) => result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    enabled: !!userId,
    select: (data) => data.length > 0,
  });
}

export async function getAtLeastOneExpiredSoonSubscription(
  supabase: SupabaseClient,
  userId: string,
  daysToExpiration: number,
) {
  const currentUtcTime = currentTime();
  const date = getDateAfterNow(daysToExpiration).toISOString();

  try {
    const { data, error } = await supabase
      .from('user_subs')
      .select(`*`)
      .eq('follower', userId)
      .eq('package', 'monthly')
      .gt('expiry', currentUtcTime)
      .lt('expiry', date)
      .limit(1);

    if (error) throw error;

    return { data } as {
      data: any[] | null;
      error: any;
    };
  } catch (error) {
    console.error('Error selecting from DB: ', error);
    return { data: null, error };
  }
}
