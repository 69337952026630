import { createContext, useContext, useEffect, useMemo } from 'react';
import { useSetOnboardingCompleteMutation } from '../api/users/mutations/useSetOnboardingCompleteMutation';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';
import useIsTelegramConnected from '../hooks/auth/useIsTelegramConnected';
import useIsDiscordConnected from '../hooks/auth/useIsDiscordConnected'; // New hook for Discord
import { convertBoolToNumber } from '../lib/convertBoolToNumber';
import { useWalletData } from '../hooks/useWalletData';
import { UserType } from '../types/UserType';
import { useAllSubPricesReadMethod } from '../web3/hooks/read/useGetSubPricesReadMethod';
import useGlobalModal from '../hooks/useGlobalModal';

interface ProfileSetupProgressData {
  isBasicProfileSetupDone: boolean;
  isTelegramSetupDone: boolean;
  isDiscordSetupDone: boolean;
  isSubPricesSetupDone: boolean;
  isTopUpSetupDone: boolean;
  isProfileSetupCompleted: boolean;
  progressPercentage: number;
  isLoading: boolean;
}

const ProfileSetupProgressContext = createContext<ProfileSetupProgressData>({
  isBasicProfileSetupDone: false,
  isTelegramSetupDone: false,
  isDiscordSetupDone: false,
  isSubPricesSetupDone: false,
  isTopUpSetupDone: false,
  isProfileSetupCompleted: false,
  progressPercentage: 0,
  isLoading: true,
});

export function ProfileSetupProgressProvider({
  children,
}: React.PropsWithChildren) {
  const { data: userData, isLoading: userDataLoading } = useCurrentUserData();
  const {
    accountConnected: telegramConnected,
    channelConnected: telegramChannelConnected,
    isLoading: isTgLoading,
  } = useIsTelegramConnected();

  const {
    accountConnected: discordConnected,
    guildConnected: discordGuildConnected,
    isLoading: isDiscordLoading,
  } = useIsDiscordConnected();

  const onboardingComplete = userData?.onboarding_complete || false;
  const userType: UserType = userData?.user_type;
  const isCreator = userType === UserType.CREATOR;

  const {
    data: subPrices,
    isSuccess,
    isLoading: isSubPricesLoading,
  } = useAllSubPricesReadMethod(userData?.wallet_address);
  const { balance, isLoading: isBalanceLoading } = useWalletData();

  const isBasicProfileSetupDone = !!userData?.wallet_address && !!userType;

  const isTelegramSetupDone =
    telegramConnected && (!isCreator || telegramChannelConnected);

  const isDiscordSetupDone =
    discordConnected && (!isCreator || discordGuildConnected);

  const isCommunitySetupDone = isTelegramSetupDone || isDiscordSetupDone;

  const isSubPricesSetupDone =
    isSuccess && !!subPrices?.[0].result && !!subPrices?.[1].result;
  const isTopUpSetupDone = !!balance;

  const stepsLoading = isCreator
    ? [
        userDataLoading,
        isBalanceLoading,
        isTgLoading,
        isDiscordLoading,
        isSubPricesLoading,
      ]
    : [userDataLoading, isBalanceLoading, isTgLoading, isDiscordLoading];

  const isLoading = stepsLoading.some((v) => !!v) && !!userData;

  const stepsResults = isCreator
    ? [
        isBasicProfileSetupDone,
        isTopUpSetupDone,
        isCommunitySetupDone,
        isSubPricesSetupDone,
      ]
    : [isBasicProfileSetupDone, isTopUpSetupDone, isCommunitySetupDone];

  const progressSum = stepsResults
    .map((value) => convertBoolToNumber(value))
    .reduce((prevValue: number, currValue: number) => prevValue + currValue, 0);

  const totalSteps = stepsResults.length;
  const progressPercentage = (progressSum / totalSteps) * 100;

  const isProfileSetupCompleted =
    onboardingComplete || progressSum === totalSteps;

  const value = useMemo(
    () => ({
      isBasicProfileSetupDone,
      isTelegramSetupDone, // Keep the updated isTelegramSetupDone logic
      isDiscordSetupDone: discordConnected,
      isSubPricesSetupDone,
      isTopUpSetupDone,
      progressPercentage,
      isProfileSetupCompleted,
      isLoading,
    }),
    [
      isSubPricesSetupDone,
      telegramConnected,
      discordConnected,
      isTopUpSetupDone,
      progressPercentage,
      isProfileSetupCompleted,
      isBasicProfileSetupDone,
      totalSteps,
      isLoading,
    ],
  );

  const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation();
  const { showModal } = useGlobalModal();

  useEffect(() => {
    if (progressSum === totalSteps && !onboardingComplete) {
      const completeOnboarding = async () => {
        await setOnboardingCompleteMutation.mutateAsync(userData.id);

        if (isCreator) {
          fetch(`/api/snapshot/${userData.username}`).catch((error) => {
            console.error('Failed to trigger snapshot', error);
          });
          showModal({ modalType: 'CreatorOnboardingWelcomeModal' });
        }
      };

      completeOnboarding();
    }
  }, [progressSum, totalSteps, onboardingComplete]);

  return (
    <ProfileSetupProgressContext.Provider value={value}>
      {children}
    </ProfileSetupProgressContext.Provider>
  );
}

export function useProfileSetupProgress() {
  return useContext(ProfileSetupProgressContext);
}
