import { MaterialDesignContent, SnackbarProvider } from 'notistack';

import { styled } from '@mui/material';

import { SuccessNotificationIcon } from '../components/icons';

export const NotificationContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 400,
    fontSize: '16px',
  },
}));

export function NotificationProvider({ children }: React.PropsWithChildren) {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2000}
      preventDuplicate
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      iconVariant={{
        success: <SuccessNotificationIcon />,
        error: '✖️',
        warning: '⚠️',
        info: 'ℹ',
      }}
      Components={{
        success: NotificationContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
