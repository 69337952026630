import { Box, Button, Typography } from '@mui/material';

import { GlobalWarningContainer } from '../../components/GlobalWarnings/GlobalWarningContainer';

import { WarningIcon, WarningImage } from '../../components/icons';
import navigate from '../../lib/navigate';
import { SIGNUP_PATH } from '../../constants/routings';
import { useUserAuthActions } from '../../hooks/useUserAuthActions';

export function SignupNotCompleted() {
  const { logoutTwitter } = useUserAuthActions();

  return (
    <Box
      sx={{
        width: '100vw',
        height: 'calc(100vh - 50px)',
        my: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 10,
          border: '1px solid #2D2F33',
          borderRadius: 3,
          maxWidth: '490px',
          flexDirection: 'column',
          gap: 6,
        }}
      >
        <WarningImage />
        <Box>
          <Typography fontSize={24} fontWeight={500} lineHeight={1.2}>
            Pick up where you left off
          </Typography>

          <Typography
            fontSize={14}
            fontWeight={300}
            color="text.secondary"
            mt={2}
          >
            Complete your sign-up process and get full access to the platform.
          </Typography>
        </Box>

        <Box display="flex" mt={4} gap={5}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(SIGNUP_PATH)}
          >
            Resume sign up
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => logoutTwitter()}
          >
            Sign out
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
