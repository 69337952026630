import { useUserTgDataByIdQuery } from '../../api/users/useUserTgDataByIdQuery';

import useCurrentUserData from './useCurrentUserData';

export default function useIsTelegramConnected() {
  const { data: userData, isLoading: userDataLoading } = useCurrentUserData();
  if (!userData)
    return {
      accountConnected: false,
      channelConnected: false,
      isLoading: true,
    };
  const { data: tgData, isLoading } = useUserTgDataByIdQuery(
    userData.id,
    !userData.id,
  );

  return {
    accountConnected: !!tgData?.tg_user_id,
    channelConnected: !!tgData?.tg_chat_id,
    isLoading: userDataLoading || isLoading,
  };
}
