import { useState } from 'react';

import { Box, Divider, Typography, useMediaQuery } from '@mui/material';

import { useMainTokenInfo } from '../hooks/useMainTokenInfo';
import { usePriceBySelectedPlan } from '../pages/app/creators/@id/subscribe/usePriceBySelectedPlan';
import { SubscriptionPlanType } from '../types/SubscriptionPlanType';

import { ChevronDown } from './icons/chevronDown';
import { ChevronRight } from './icons/chevronRight';
import { CheckEmptyCircle } from './icons';

export function SelectionPlanSidePanel({
  planSelected,
  handlePlanChange,
  username,
}: {
  planSelected: SubscriptionPlanType;
  handlePlanChange?: () => void;
  username: string;
}) {
  const { symbol } = useMainTokenInfo();
  const [expandDetails, setExpandDetails] = useState(
    useMediaQuery('(min-width: 960px)'),
  );

  const { selectedPriceInWei, selectedPriceInEthStr } = usePriceBySelectedPlan(
    username,
    planSelected,
  );

  const handleExpandDetails = () => {
    setExpandDetails(!expandDetails);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid #2D2F33',
        borderRadius: 3,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out',
        maxHeight: expandDetails ? '600px' : '200px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: { xs: 5, md: 6 },
        }}
      >
        <Typography>
          {planSelected === SubscriptionPlanType.LIFETIME
            ? 'ALPHA'
            : planSelected}
        </Typography>

        <Box display="flex" gap={1} paddingTop={3}>
          <Typography fontSize={{ xs: '24px', md: '32px' }}>
            {selectedPriceInEthStr} {symbol}
          </Typography>

          {planSelected === SubscriptionPlanType.MONTHLY && (
            <Typography variant="body1">/month</Typography>
          )}
        </Box>

        <Typography
          paddingTop={1}
          maxWidth="229px"
          variant="body2"
          color="#ACAFB3"
        >
          {planSelected === SubscriptionPlanType.MONTHLY &&
            'Monthly community and staking access'}
          {planSelected === SubscriptionPlanType.LIFETIME &&
            'Lifetime staking rights, lifetime community access & maximum point rewards'}
        </Typography>
        <Box sx={{ display: 'flex', gap: 5, pt: { xs: 5, md: 6 } }}>
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              gap: 2,
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleExpandDetails}
          >
            <Typography fontSize={{ xs: '14px', md: '16px' }} fontWeight={600}>
              View details
            </Typography>

            <ChevronDown isDown={false} />
          </Box>
          {!!handlePlanChange && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handlePlanChange}
            >
              <Typography
                fontSize={{ xs: '14px', md: '16px' }}
                fontWeight={600}
              >
                Switch plan
              </Typography>

              <ChevronRight size={14} />
            </Box>
          )}
        </Box>
      </Box>

      <Divider
        sx={{
          display: expandDetails ? 'flex' : 'none',
        }}
      />

      <Box
        sx={{
          display: expandDetails ? 'flex' : 'none',
          flexDirection: 'column',
          gap: 3,
          padding: 6,
        }}
      >
        <Typography variant="body2" color="#ACAFB3">
          What's included
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box display="flex" gap={2} alignItems="center">
            <CheckEmptyCircle />

            <Typography variant="body2" color="#E3E4E6">
              Private Community Access
            </Typography>
          </Box>

          <Box display="flex" gap={2} alignItems="center">
            <CheckEmptyCircle />

            <Typography variant="body2" color="#E3E4E6">
              Community Staking Pool Access
            </Typography>
          </Box>

          <Box display="flex" gap={2} alignItems="center">
            <CheckEmptyCircle />

            <Typography
              variant="body2"
              sx={{ display: 'flex', gap: 1, color: '#E3E4E6' }}
            >
              <Typography fontWeight={700} variant="body2">
                {planSelected === SubscriptionPlanType.MONTHLY && 'BASIC'}
                {planSelected === SubscriptionPlanType.LIFETIME && 'Maximum'}
              </Typography>
              Point Rewards
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
