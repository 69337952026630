import { useUserDiscordDataByIdQuery } from '../../api/users/useUserDiscordDataById';
import useCurrentUserData from './useCurrentUserData';

export default function useIsDiscordConnected() {
  const { data: userData, isLoading: userDataLoading } = useCurrentUserData();

  const { data: discordData, isLoading: discordDataLoading } =
    useUserDiscordDataByIdQuery(userData?.id, !userData?.id);

  return {
    accountConnected: !!discordData?.discord_user_id,
    guildConnected: !!discordData?.guild_id,
    isLoading: userDataLoading || discordDataLoading,
  };
}
