import { useEffect, useState } from 'react';

import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';

import { useGeolocation } from '../api/users/useGeolocation';
import {
  creatorConnectCommunity,
  setupBasicProfileStep,
} from '../constants/setupAccountSteps';
import useIsDiscordConnected from '../hooks/auth/useIsDiscordConnected';
import useIsTelegramConnected from '../hooks/auth/useIsTelegramConnected';
import useGlobalModal from '../hooks/useGlobalModal';
import { useProfileSetupProgress } from '../providers/ProfileSetupProgressProvider';

import CreatorSetupCard from './shared/Cards/CreatorSetupCard';
import { ConnectDiscordSetupCard } from './shared/SetupCards/ConnectDiscordSetupCard';
import { ConnectTelegramGroupSetupCard } from './shared/SetupCards/ConnectTelegramGroupSetupCard';
import { ConnectTelegramSetupCard } from './shared/SetupCards/ConnectTelegramSetupCard';
import { SetupPricingCard } from './shared/SetupCards/SetupPricingCard';
import { TopUpCard } from './shared/SetupCards/TopUpCard';
import { CustomCircularProgress } from './CustomCircularProgress';

export enum CommunityPick {
  TELEGRAM = 'telegram',
  DISCORD = 'discord',
}

export default function UserCardsOnboardSteps({
  isCreator,
}: {
  isCreator: boolean;
}) {
  const { accountConnected: telegramConnected } = useIsTelegramConnected();
  const { accountConnected: discordConnected } = useIsDiscordConnected();

  const { data: geoData, isLoading: isLoadingGeoData } = useGeolocation();
  const { showModal } = useGlobalModal();

  const [communityPick, setCommunityPick] = useState<CommunityPick | null>(
    null,
  );

  const { isBasicProfileSetupDone, progressPercentage, isLoading } =
    useProfileSetupProgress();

  useEffect(() => {
    if (telegramConnected) {
      setCommunityPick(CommunityPick.TELEGRAM);
    } else if (discordConnected) {
      setCommunityPick(CommunityPick.DISCORD);
    } else {
      setCommunityPick(null);
    }
  }, [telegramConnected, discordConnected]);

  if (isLoading || isLoadingGeoData) {
    return (
      <Box>
        <Skeleton
          component="div"
          sx={{ display: 'flex' }}
          variant="rounded"
          height="390px"
        />
      </Box>
    );
  }

  const isUsaUser = geoData?.isBlocked ?? false;

  const mdCardColumns = isCreator ? 4 : 3;

  const handleCommunityPick = () => {
    showModal({
      modalType: 'CommunityPickModal',
      modalProps: { setCommunityPick: setCommunityPick },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            backgroundColor: '#182920',
            borderRadius: '40px',
            px: 4,
            py: '10px',
          }}
        >
          <CustomCircularProgress percentage={progressPercentage} />
          <Typography variant="body2">
            {progressPercentage.toFixed()}% Complete
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h3">
          {isCreator
            ? 'Complete your setup as a Creator'
            : 'Complete your profile'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {isCreator
            ? `It's time to monetize, share revenue, and grow together with your community onchain.`
            : 'Complete these tasks to take advantage of Altcoinist platform.'}
        </Typography>
      </Box>

      <Grid container spacing={5} columns={{ xs: 1, md: mdCardColumns }}>
        <CreatorSetupCard
          key={setupBasicProfileStep.key}
          buttonText={setupBasicProfileStep.buttonText}
          action={setupBasicProfileStep.action}
          title={setupBasicProfileStep.title}
          description={setupBasicProfileStep.description}
          icon={setupBasicProfileStep.icon}
          isDone={isBasicProfileSetupDone}
        />

        <TopUpCard />

        {!isCreator && <ConnectTelegramSetupCard isCreator={false} />}

        {isCreator && (
          <>
            <SetupPricingCard isUsaUser={isUsaUser} />

            {telegramConnected && <ConnectTelegramGroupSetupCard />}

            {!communityPick && !telegramConnected && (
              <CreatorSetupCard
                key={creatorConnectCommunity.key}
                buttonText={creatorConnectCommunity.buttonText}
                action={handleCommunityPick}
                title={creatorConnectCommunity.title}
                description={creatorConnectCommunity.description}
                icon={creatorConnectCommunity.icon}
                isDone={false}
              />
            )}
            {communityPick === CommunityPick.DISCORD && (
              <ConnectDiscordSetupCard />
            )}
          </>
        )}
      </Grid>
    </>
  );
}
