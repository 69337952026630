import { Address } from 'viem';
import { useSimulateContract } from 'wagmi';

import WethAbi from '../../../assets/abi/WETH';

export function getWrapEthContract(amount: bigint): {
  address: Address;
  abi: any;
  functionName: 'deposit';
  value: bigint;
} {
  return {
    address: import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address,
    abi: WethAbi,
    functionName: 'deposit',
    value: amount,
  };
}

export function useWrapEthSimulateContract(amount: bigint | undefined) {
  return useSimulateContract({
    address: import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address,
    abi: WethAbi,
    functionName: 'deposit',
    value: amount,
    query: {
      enabled: !!amount,
    },
  });
}
