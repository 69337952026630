import { connectTelegramGroupStep } from '../../../constants/setupAccountSteps';
import { useTgConnection } from '../../../hooks/useTgConnection';
import { useProfileSetupProgress } from '../../../providers/ProfileSetupProgressProvider';
import CreatorSetupCard from '../Cards/CreatorSetupCard';

export function ConnectTelegramGroupSetupCard() {
  const { connectTgChannel } = useTgConnection();
  const { isTelegramSetupDone } = useProfileSetupProgress();

  return (
    <CreatorSetupCard
      key={connectTelegramGroupStep.key}
      buttonText={connectTelegramGroupStep.buttonText}
      action={connectTgChannel}
      isDone={isTelegramSetupDone}
      disabled={isTelegramSetupDone}
      title={connectTelegramGroupStep.title}
      description={connectTelegramGroupStep.description}
      icon={connectTelegramGroupStep.icon}
    />
  );
}
