import { useEffect } from 'react';
import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import { Address } from 'viem';
import { useAccount } from 'wagmi';
import {
  useCallsStatus,
  useCapabilities,
  useWriteContracts,
} from 'wagmi/experimental';

import { Box, Button, Typography } from '@mui/material';

import { MaintenanceWarningIcon } from '../../components/icons';
import { FeedbackBanner } from '../../components/shared/FeedbackBanner';
import { useWalletData } from '../../hooks/useWalletData';
import { useAllowanceReadMethod } from '../../web3/hooks/ERC20/useAllowanceReadMethod';
import { getApproveContract } from '../../web3/hooks/ERC20/useApproveSimulateContract';

export function OldPullPaymentApprovedWarningContent() {
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useWalletData();

  const paymentCollectorAddress = import.meta.env
    .VITE_PAYMENT_COLLECTOR_ADDRESS as Address;

  const { data: pullPaymentAllowance } = useAllowanceReadMethod(
    address,
    paymentCollectorAddress,
  );

  const sumToApprovePullPayment = ethers.MaxUint256 - 1n;

  const pullPaymentApproved =
    !!pullPaymentAllowance && pullPaymentAllowance >= sumToApprovePullPayment;

  const oldPaymentCollectorAddress = import.meta.env
    .VITE_OLD_PAYMENT_COLLECTOR_ADDRESS as Address;

  const { data: oldPullPaymentAllowance } = useAllowanceReadMethod(
    address,
    oldPaymentCollectorAddress,
  );

  const oldPullPaymentApproved = !!oldPullPaymentAllowance;

  const { data: capabilities, isLoading: isCapabilitiesLoading } =
    useCapabilities();
  const { chainId } = useAccount();

  const isUseBatching =
    capabilities && chainId && !!capabilities[chainId]?.atomicBatch?.supported;

  const { data: batchTxId, writeContracts } = useWriteContracts();
  const { data: callsStatus, failureReason: batchFailureReason } =
    useCallsStatus({
      id: batchTxId as string,
      query: {
        enabled: !!batchTxId,
        // Poll every second until the calls are confirmed
        refetchInterval: (data) =>
          data.state.data?.status === 'CONFIRMED' ? false : 1000,
      },
    });

  const disabledBatchMethod = !isUseBatching;

  const batchFailureMsg = (() => {
    switch (true) {
      case !isUseBatching:
        return 'Batching is not supported on this wallet or chain.';
    }
    return (batchFailureReason?.cause as any)?.shortMessage as
      | string
      | undefined;
  })();

  const handleUpdateNow = () => {
    if (disabledBatchMethod) {
      return;
    }

    const contracts = [];
    if (oldPullPaymentApproved) {
      contracts.push(getApproveContract(oldPaymentCollectorAddress, 0n));
    }

    if (!pullPaymentApproved) {
      contracts.push(
        getApproveContract(paymentCollectorAddress, sumToApprovePullPayment),
      );
    }

    writeContracts(
      {
        contracts: [...contracts],
      },
      {
        onError: (error) => {
          enqueueSnackbar(`Update failed, because of error: ${error.message}`, {
            variant: 'error',
          });
        },
        onSuccess: onUpdateSuccess,
      },
    );
  };

  const onUpdateSuccess = () => {
    enqueueSnackbar(`Smartcontract allowance updated successfully!`, {
      variant: 'success',
    });
  };

  useEffect(() => {
    if (callsStatus?.status === 'CONFIRMED') {
      window.location.reload();
    }
  }, [callsStatus]);

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box display="flex" justifyContent="center">
        <MaintenanceWarningIcon />
      </Box>

      <Box>
        <Typography
          fontSize={24}
          fontWeight={500}
          lineHeight={1.2}
          textAlign="center"
        >
          Confirm Pull Payments Update
        </Typography>

        <Typography
          fontSize={14}
          fontWeight={300}
          color="text.secondary"
          mt={2}
          textAlign="center"
        >
          For seamless recurring payments on your subscriptions, please approve
          our updated payment collector contract by confirming below.
        </Typography>

        {batchFailureMsg && !isCapabilitiesLoading && (
          <Box mt={2}>
            <FeedbackBanner type="error" title={batchFailureMsg} />
          </Box>
        )}
      </Box>

      <Box display="flex" mt={4} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateNow}
          disabled={
            disabledBatchMethod ||
            (callsStatus && callsStatus.status !== 'CONFIRMED')
          }
        >
          Confirm Update
        </Button>
      </Box>
    </Box>
  );
}
